<template>
  <div id="app">
    <div class="container" style="text-align: center">
      <el-dialog
        title="TK云大师"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
        custom-class="centered-dialog"
      >
        <div class="dialog-content">
          <h2>TK业务交流</h2>
          <el-image src="tkyds.jpg" />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div style="margin-top: 5em">
        <el-button type="primary" round :disabled="loading" @click="one_key">一键重置</el-button>
      </div>
      <div style="margin-top: 2em">
        <el-button type="primary" round :disabled="loading" @click="clear_tk">清理TikToK</el-button>
      </div>
      <div style="margin-top: 2em">
        <el-checkbox v-model="rand_board">海外随机</el-checkbox>
        <el-checkbox v-model="use_english">使用英语</el-checkbox>
      </div>
      <div v-show="false" style="position: absolute;bottom: 10px">
        <el-link>AAA</el-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      loading: false,
      centerDialogVisible: true,
      rand_board: true,
      use_english: false
    }
  },
  methods: {
    async one_key() {
      this.loading = true
      const start_time = new Date().getTime()
      this.$message({
        message: '新机中...',
        type: 'info'
      })
      try {
        const url = `http://127.0.0.1:9082/modifydev?cmd=2&random_abroad=${this.rand_board}&use_english=${this.use_english}`
        await fetch(url, { mode: 'no-cors' }).finally(() => {
          const end_time = new Date().getTime()
          if (end_time - start_time < 2000) {
            this.$message({
              message: '请在云手机内打开',
              type: 'warning'
            })
          } else {
            this.$message({
              message: '新机成功',
              type: 'success'
            })
          }
          this.loading = false
        })
      } catch (e) {
        console.log(e)
      }
    },
    async clear_tk() {
      this.loading = true
      try {
        const url = `http://127.0.0.1:9082/modifydev?cmd=6&cmdline=pm%20clear%20com.zhiliaoapp.musically`
        await fetch(url, { mode: 'no-cors' }).finally(() => {
          this.$message({
            message: '清理成功',
            type: 'success'
          })
          this.loading = false
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.center-button {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-dialog {
  width: 80% !important;
}

</style>

<style scoped>

.centered-dialog .dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* 可选，用于文本中心对齐 */
  height: 100%; /* 确保内容区域充满对话框 */
}
</style>
